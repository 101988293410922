//User Actions
export const MOSTRAR_USERS = "MOSTRAR_USERS";
export const CREAR_USER = "CREAR_USER";
export const ELIMINAR_USER = "ELIMINAR_USER";
export const ACTUALIZAR_USER = "ACTUALIZAR_USER";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const CHECKTOKEN_USER = "CHECKTOKEN_USER";
export const GET_USER = "GET_USER";

//Patient Actions
export const MOSTRAR_PATIENTS = "MOSTRAR_PATIENTS";
export const CREAR_PATIENT = "CREAR_PATIENT";
export const ELIMINAR_PATIENT = "ELIMINAR_PATIENT";
export const ACTUALIZAR_PATIENT = "ACTUALIZAR_PATIENT";
export const GET_PATIENT = "GET_PATIENT";

//Ejercicios Actions
export const MOSTRAR_EJERCICIOS = "MOSTRAR_EJERCICIOS";
export const CREAR_EJERCICIO = "CREAR_EJERCICIO";
export const ELIMINAR_EJERCICIO = "ELIMINAR_EJERCICIO";
export const ACTUALIZAR_EJERCICIO = "ACTUALIZAR_EJERCICIO";
export const GET_EJERCICIO = "GET_EJERCICIO";

//Recomendaciones Actions
export const MOSTRAR_RECOMENDACIONES = "MOSTRAR_RECOMENDACIONES";
export const CREAR_RECOMENDACION = "CREAR_RECOMENDACION";
export const ELIMINAR_RECOMENDACION = "ELIMINAR_RECOMENDACION";
export const ACTUALIZAR_RECOMENDACION = "ACTUALIZAR_RECOMENDACION";

//
export const MOSTRAR_RESULTS = "MOSTRAR_RESULTS";